<template>
  <div class="avatar" :class="{ [`avatar--${size}`]: size }">
    <AImage
      v-if="avatar"
      :src="avatar"
      :width="imgSize"
      :height="imgSize"
      provider="public"
      alt="User avatar"
    />
    <template v-else>
      {{ formattedName }}
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from 'vue'
import AImage from '@/components/atoms/Image/AImage.vue'

import type { PropType } from 'vue'

export type AvatarSizes = 'lg' | 'base' | 'sm' | 'xs'

export default defineComponent({
  name: 'AAvatar',
  components: { AImage },
  props: {
    avatar: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    size: {
      type: String as PropType<AvatarSizes>,
      default: undefined
    }
  },
  setup (props) {
    const { size, name } = toRefs(props)
    const sizes = {
      xl: 56,
      lg: 48,
      base: 40,
      sm: 24,
      xs: 20
    }

    const imgSize = computed(() =>
      size.value !== undefined ? sizes[size.value] : sizes.xl
    )
    const formattedName = computed(() => name.value?.charAt(0).toUpperCase() ?? '')

    return {
      imgSize,
      formattedName
    }
  }
})
</script>

<style lang="postcss">
/**
 * Default: size - xl
 */
.avatar {
  --avatar-size: 3.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: var(--avatar-size);
  min-width: var(--avatar-size);
  height: var(--avatar-size);
  min-height: var(--avatar-size);
  border-radius: var(--border-radius-full);
  background-color: var(--color-neutral-400);
  color: var(--color-white);
  user-select: none;

  & > .image img {
    object-fit: contain;
    height: 100%;
    aspect-ratio: 1 / 1;
  }

  @mixin text-5xl;
}

.avatar--lg {
  --avatar-size: 3rem;

  @mixin text-5xl;
}

.avatar--base {
  --avatar-size: 2.5rem;

  @mixin text-2xl;
}

.avatar--sm {
  --avatar-size: 1.5rem;

  @mixin text-base-bold;
}

.avatar--xs {
  --avatar-size: 1.25rem;

  @mixin text-sm-bold;
}
</style>
