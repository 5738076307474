<template>
  <div class="o-blog__container container">
    <div class="o-blog__menu">
      <AImage
        src="/assets/images/blog/blog-logo.svg"
        alt="blog-logo"
        width="228"
        height="96"
        provider="public"
      />
      <ul>
        <li
          v-for="(article, i) in articles"
          :key="`${article}-${i}`"
        >
          <NuxtLink
            :to="getUrl(`articles/${article}`)"
            class="o-blog__menu-item"
            no-prefetch
          >
            <AImage
              :src="`/assets/images/blog/blog-${article}.png`"
              :alt="article"
              width="56"
              height="56"
              provider="public"
              with-webp
            />
            <span>{{ $t(`organisms.blog.${article}`) }}</span>
          </NuxtLink>
        </li>
      </ul>
      <AButton
        size="xl"
        @click="navigatePush(`articles`)"
      >
        {{ $t("organisms.blog.all-articles") }}
      </AButton>
    </div>
    <div class="o-blog__carousel">
      <MCarousel v-if="blogCards?.length">
        <MBlogCard
          v-for="(card, idx) in blogCards"
          :key="card.id"
          :card="card"
          :saved="card.saved"
          :theme="idx % 3 === 0 ? undefined : 'light'"
          variant="carousel"
          @toggle:save="toggleSave(idx)"
        />
      </MCarousel>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineComponent, ref } from 'vue'
import { useNavigate } from '@/composables/useNavigate'
import { useBlog } from '@/composables/blog'

import type { BlogCard } from '@/components/molecules/BlogCard/types'

// Atoms
import AButton from '@/components/atoms/Button/AButton.vue'
import AImage from '@/components/atoms/Image/AImage.vue'

// Molecules
import MCarousel from '@/components/molecules/Carousel/MCarousel.vue'
import MBlogCard from '@/components/molecules/BlogCard/MBlogCard.vue'

defineComponent({ name: 'OBlog' })

const { getUrl, navigatePush } = useNavigate()
const { getArticles, getBlogCards } = useBlog()

const blogCards = ref<BlogCard[]>(getBlogCards())
const articles = ref<string[]>(getArticles())

const toggleSave = (idx: number): void => {
  if (blogCards.value?.[idx]) {
    blogCards.value[idx].saved = !blogCards.value[idx].saved
  }
}
</script>

<style lang="postcss">
.o-blog {
  border-bottom: 1px solid var(--color-neutral-800);
  background: var(--color-neutral-900);

  &__container {
    padding: var(--spacer-3xl) var(--spacer-lg);
  }

  &__menu {
    flex-shrink: 0;
    width: 20rem;

    & > .image img {
      width: 14.25rem;
      height: 6rem;
    }

    & > ul {
      overflow-y: auto;
      height: 14.5rem;
      margin-top: var(--spacer-4xl);
      margin-bottom: var(--spacer-3xs);

      &::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar-track {
        border-radius: var(--border-radius-2xs);
        background-color: var(--color-neutral-800);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: var(--border-radius-2xs);
        background-color: var(--color-neutral-500);
      }

      & > li + li {
        margin-top: var(--spacer-xs);
      }

      @media (hover: hover) and (--screen-lg) {
        &:hover {
          &::-webkit-scrollbar {
            display: block;
            width: var(--spacer-4xs);
          }
        }
      }
    }
  }

  &__menu-item {
    display: flex;
    gap: var(--spacer-xs);
    align-items: center;
    color: var(--color-text-light);

    & > .image img {
      width: 3.5rem;
      height: 3.5rem;
    }

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        color: var(--color-white);
      }
    }
  }

  &__carousel {
    width: 100%;
    min-width: 0;
  }

  @media (--screen-lg) {
    &__container {
      display: flex;
      gap: var(--spacer-3xl);
    }
  }

  @media (--screen-xs) {
    border-top-left-radius: var(--border-radius-xs);
    border-top-right-radius: var(--border-radius-xs);

    &__container.container {
      padding: var(--spacer-base) var(--spacer-xs);
    }

    &__menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: var(--spacer-base);

      & > .button {
        height: var(--spacer-xl);
        background: var(--color-neutral-200);
        color: var(--color-black);
        white-space: nowrap;

        @mixin text-sm-bold;
      }

      & > .image > img {
        width: 8.75rem;
        height: 3.75rem;
      }

      & > ul {
        display: none;
      }
    }

    &__carousel > .carousel {
      --carousel-item-width: 11.75rem;
      --carousel-gap: var(--spacer-3xs);
    }
  }

  @media (--screen-lg) {
    &__menu {
      & > .button {
        width: 100%;
      }
    }

    &__carousel > .carousel {
      --carousel-item-width: calc((100% - var(--spacer-base)) / 2);
      --carousel-gap: var(--spacer-base);
    }
  }

  @media (--screen-base) {
    &__carousel > .carousel {
      --carousel-item-width: calc((100% - 2 * var(--spacer-base)) / 3);
      --carousel-gap: var(--spacer-base);
    }
  }
}
</style>
