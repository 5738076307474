<template>
  <NuxtLink
    :to="getUrl(card.link)"
    class="m-blog-card"
    :class="{
      [ `m-blog-card--${variant}` ]: variant,
      [ `m-blog-card--${theme}` ]: theme,
    }"
    no-prefetch
  >
    <AImage
      v-if="card.src"
      :src="card.src"
      :mobile-src="card.mobileSrc"
      width="336"
      height="440"
      provider="public"
      with-retina
      with-webp
    />
    <div>
      <div>
        <ServerRender is="Badge" v-if="card.new" bevel-corner="right-bottom">
          {{ $t("molecules.blog-card.new") }}
        </ServerRender>

        <div
          class="m-blog-card__user"
          :class="{ 'i-verification': card.user.verified }"
        >
          <span>{{ card.user.name }}</span>
          <!-- [AMVP] avatar img -->
          <AAvatar
            :name="card.user.name"
            size="sm"
          />
        </div>
        <div class="m-blog-card__description">
          <span>
            {{ card.category.toUpperCase() }}
          </span>
          <div class="m-blog-card__title">
            {{ card.title }}
          </div>
          <div class="m-blog-card__focus">
            <span
              v-if="card.date"
              class="i-date-grey"
            >
              {{ card.date }}
            </span>
            <span
              v-if="card.seen"
              class="i-eye-grey"
            >
              {{ card.seen }}
            </span>
          </div>
          <AIconButton
            :icon-name="saved ? 'bookmark' : 'bookmark-outline'"
            class="m-blog-card__bookmark"
            :class="{ 'm-blog-card__bookmark--saved': saved }"
            @click.prevent="$emit('toggle:save')"
          />
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import ServerRender from '@/components/helpers/ServerRender'

import { defineComponent } from 'vue'
import { useNavigate } from '@/composables/useNavigate'

import type { PropType } from 'vue'
import type { BlogCard, BlogCardVariants, BlogCardThemes } from '@/components/molecules/BlogCard/types'

// Atoms
import AAvatar from '@/components/atoms/Avatar/AAvatar.vue'
import AIconButton from '@/components/atoms/IconButton/AIconButton.vue'
import AImage from '@/components/atoms/Image/AImage.vue'

defineComponent({ name: 'MBlogCard' })

defineProps({
  card: {
    type: Object as PropType<BlogCard>,
    required: true
  },
  saved: {
    type: Boolean,
    default: false
  },
  variant: {
    type: String as PropType<BlogCardVariants>,
    default: undefined
  },
  theme: {
    type: String as PropType<BlogCardThemes>,
    default: undefined
  }
})

defineEmits(['toggle:save'])

const { getUrl } = useNavigate()
</script>

<style lang="postcss">
.m-blog-card {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 27.5rem;
  border-radius: var(--border-radius-lg);
  isolation: isolate;

  & > .image {
    position: relative;
    overflow: hidden;
    min-width: 100%;
    height: 100%;

    & > img {
      object-fit: cover;
      min-height: 100%;
      transition: transform 0.3s linear;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      inset: 0;
    }

    &::before {
      background-color: var(--bg-shadow-base);
      opacity: 0;
      transition: opacity 0.3s linear;
    }

    &::after {
      background-image: linear-gradient(
        0deg,
        var(--color-neutral-900) 0%,
        var(--color-neutral-900) 20%,
        rgb(42 36 37 / 0%) 65%
      );
    }
  }

  & > div:last-child {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    inset: 0;
  }

  & .badge {
    position: absolute;
    top: var(--spacer-xs);
    right: var(--spacer-base);
  }

  &__bookmark {
    position: absolute;
    right: var(--spacer-3xs);
    bottom: var(--spacer-3xs);
    display: flex;
  }

  &__user {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    padding: 0 var(--spacer-xs);
    color: var(--color-white);

    & > .avatar {
      --avatar-size: 1.5rem;

      flex-shrink: 0;
    }

    & > span {
      position: relative;
      display: -webkit-box;
      overflow: hidden;
      margin-right: var(--spacer-4xs);
      margin-left: var(--spacer-3xs);
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    &::before {
      width: 1rem;
      min-width: 1rem;
      height: 1rem;
    }

    @mixin text-base-semibold;
  }

  &__description {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--spacer-xs);

    & > span:first-child {
      margin-bottom: var(--spacer-4xs);
      color: var(--color-blue-300);
    }

    @mixin text-sm-semibold;
  }

  &__focus {
    display: flex;
    gap: var(--spacer-base);
    margin-top: var(--spacer-xs);
    color: var(--color-text-light);

    & > span {
      display: flex;
      gap: var(--spacer-4xs);
      align-items: center;

      &::before {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &__title {
    display: -webkit-box;
    overflow: hidden;
    height: 100%;
    color: var(--color-white);
    white-space: normal;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @mixin text-xl;
  }

  &--light {
    & .image {
      height: 16.5rem;

      &::after {
        background-image: linear-gradient(
          0deg,
          var(--color-neutral-900) 0%,
          rgb(42 36 37 / 0%) 35%
        );
      }
    }

    & .m-blog-card__user {
      padding-bottom: var(--spacer-xs);
    }

    & .m-blog-card__description {
      & > span:first-child {
        color: var(--color-blue-600);
      }

      & > div:first-of-type {
        color: var(--color-text-dark);
      }
    }

    &:not(.m-blog-card--carousel) {
      height: 100%;
      min-height: 23.5rem;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      & > .image {
        margin-bottom: calc(var(--spacer-3xl) * -1);
      }

      & > div:last-child {
        position: static;
      }

      & .m-blog-card__description {
        padding: var(--spacer-xs) 0 var(--spacer-3xs);
      }

      & .m-blog-card__bookmark {
        right: 0;
        bottom: 0;
      }
    }
  }

  &--carousel {
    & .m-blog-card__user {
      padding: 0 var(--spacer-xs) var(--spacer-sm);
    }

    & .m-blog-card__description {
      height: 11rem;
    }

    &.m-blog-card--light .m-blog-card__description {
      background-color: var(--color-white);
    }
  }
}

@media (hover: hover) and (--screen-lg) {
  .m-blog-card {
    &:hover > .image {
      & > img {
        transform: scale(1.05);
      }

      &::before {
        opacity: 1;
      }
    }

    &__bookmark {
      &:hover.i-bookmark::before {
        background-image: url("/assets/icons/general.svg#bookmark--hover");
      }

      &:hover.i-bookmark-outline::before {
        background-image: url("/assets/icons/general.svg#bookmark-outline--hover");
      }
    }

    &__description > span:first-child:hover {
      color: var(--color-blue-800);
    }
  }
}

@media (--screen-xs) {
  .m-blog-card {
    height: 14.5rem;

    & .badge {
      top: var(--spacer-2xs);
      right: var(--spacer-xs);
    }

    &__bookmark {
      right: 0;
      bottom: 0;
    }

    &__user {
      padding: 0 var(--spacer-2xs) var(--spacer-4xs);

      & > .avatar {
        --avatar-size: 1.25rem;
      }

      & > span {
        margin-right: 0;
      }

      @mixin text-xs-semibold;
    }

    &__description {
      padding: var(--spacer-2xs);

      @mixin text-xs-semibold;
    }

    &__focus {
      display: flex;
      gap: var(--spacer-2xs);
      margin-top: var(--spacer-3xs);
    }

    &__title {
      @mixin text-sm-bold;
    }

    &--light {
      height: auto;

      & .m-blog-card__user {
        padding: 0 var(--spacer-2xs) var(--spacer-2xs);
      }

      & > .image {
        height: 12rem;
      }

      &:not(.m-blog-card--carousel) {
        min-height: 15.5rem;

        & > .image {
          margin-bottom: calc(var(--spacer-xl) * -1);
        }

        & .m-blog-card__description {
          padding: var(--spacer-2xs) 0;
        }

        & > .image {
          height: 10rem;
        }
      }
    }

    &--carousel {
      height: 20rem;

      & .m-blog-card__user {
        padding: 0 var(--spacer-2xs) var(--spacer-2xs);
      }

      & .m-blog-card__description {
        height: 8rem;
      }
    }
  }
}

/*
  Default: variant - page, theme - dark
 */
</style>
